import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/lib/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: process.env.VUE_APP_COLOR_PRIMARY,
        secondary: process.env.VUE_APP_COLOR_SECONDARY,
        accent: process.env.VUE_APP_COLOR_ACCENT,
        error: process.env.VUE_APP_COLOR_ERROR,
        background: process.env.VUE_APP_COLOR_BACKGROUND,
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
    },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
  icons: {
    iconfont: 'md',
  },
});
