<template>
  <v-app>
    <AppBar v-if="showAppBar" />
    <v-main>
      <vue-page-transition name="fade">
        <router-view
          :class="showClasses"
          @msg="_notify"
          @httpError="_resFail"
          @classes="_classes"
          @visible="_visible"
        />
      </vue-page-transition>
    </v-main>
    <v-snackbar
      style="border-radius: 100px!important;"
      v-model="snackbar.show"
      bottom="bottom"
      :timeout="4500"
      rounded="pill"
    >
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          icon
          @click="snackbar.show = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
      {{ snackbar.text }}
    </v-snackbar>
  </v-app>
</template>

<script>
import AppBar from "@/components/appbar/AppBar";
export default {
  name: "App",
  components: { AppBar },
  methods: {
    _notify(a) {
      this.snackbar.text = a;
      this.snackbar.show = true;
    },
    _resFail(a) {
      if (a.status === 401) {
        this.$router.push("/login");
      } else if (a.status === 400) {
        this._notify(a.body.error);
      } else if (a.body.error) {
        this._notify(a.body.error);
      } else {
        this._notify(this.$noInternet);
      }
    },
    _visible(a) {
      this.showAppBar = a;
    },
    _classes(a) {
      this.showClasses = a;
    },
  },
  created() {
    this.$headers.headers.Token =
      this.$session.exists() && this.$session.get("token")
        ? this.$session.get("token")
        : null;
  },
  data() {
    return {
      showClasses: "",
      snackbar: {
        show: false,
        text: "",
      },
      showAppBar: false,
    };
  },
};
</script>
<style lang="scss">
@import "styles/sass/_app";
</style>