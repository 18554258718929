<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      floating
      temporary
      width="280"
    >
      <AppMenu v-if="drawer" />
    </v-navigation-drawer>
    <v-app-bar
      app
      collapse
      color="secondary"
      dark
      dense
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

    </v-app-bar>
  </div>
</template>
<script>
import AppMenu from "@/components/appbar/AppMenu";
export default {
  name: "AppBar",
  components: { AppMenu },
  data() {
    return {
      drawer: false,
    };
  },
};
</script>