import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue')
  }, {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue')
  }, {
    path: '/categorias',
    name: 'CategoriasListado',
    component: () => import('@/views/categorias/CategoriasListado.vue')
  }, {
    path: '/clientes',
    name: 'ClientesListado',
    component: () => import('@/views/clientes/ClientesListado.vue')
  }, {
    path: '/cliente/:id',
    name: 'ClientesDetalle',
    component: () => import('@/views/clientes/ClientesDetalle.vue'),
    children: [
      {
        path: 'ordenes',
        component: () => import('@/views/clientes/ClientesOrdenes.vue'),
      },
      {
        path: 'notas',
        component: () => import('@/views/clientes/ClientesNotas.vue'),
      },
    ]
  }, {
    path: '/menu',
    name: 'MenuListado',
    component: () => import('@/views/menu/MenuListado.vue')
  }, {
    path: '/ordenes',
    name: 'OrdenesListado',
    component: () => import('@/views/ordenes/OrdenesListado.vue')
  }, {
    path: '/sucursales',
    name: 'SucursalesListado',
    component: () => import('@/views/sucursales/SucursalesListado.vue')
  }, {
    path: '/usuarios',
    name: 'UsuariosListado',
    component: () => import('@/views/usuarios/UsuariosListado.vue')
  }, {
    path: '/*',
    name: '404',
    component: () => import('@/views/404.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
