<template>
  <v-list
    nav
    shaped
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title
          class="title"
          style="padding:8px 0"
          v-text="'Hakuna'"
        />
      </v-list-item-content>
    </v-list-item>
    <v-list-item-group
      active-class="primary"
      color="#fefffe"
    >
      <div
        v-for="(item,idx) in menu"
        :key="idx"
      >
        <div
          v-if="_mustShow(item.permitido)"
          class="mb4"
        >
          <ListItemMenu
            v-if="item.component=='item'"
            :icon="item.icon"
            :title="item.title"
            :to="item.to"
          />
          <ListGroupMenu
            v-if="item.component=='group'"
            :icon="item.icon"
            :title="item.title"
            :items="item.items"
          />
        </div>
      </div>
      <ListItemMenu
        @click="this.$session.destroy()"
        icon="exit_to_app"
        title="Cerrar sesión"
        to="/login"
      />
      <v-divider class="mt24 mb24" />
      <v-list-item
        dense
        disabled
      >
        <v-list-item-content>
          <v-list-item-title v-text="$version" />
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
<script>
import ListItemMenu from "@/components/lists/ListItemMenu";
import ListGroupMenu from "@/components/lists/ListGroupMenu";
export default {
  name: "AppMenu",
  components: { ListItemMenu, ListGroupMenu },
  data() {
    return {
      tipo: null,
      menu: [
        {
          component: "item",
          icon: "home",
          title: "Inicio",
          to: "/",
          permitido: "todos",
        },
        {
          component: "item",
          icon: "face",
          title: "Clientes",
          to: "/clientes",
          permitido: "todos",
        },
        {
          component: "group",
          icon: "loyalty",
          title: "Menú",
          permitido: "todos",
          items: [
            {
              text: "Productos registrados",
              to: "/menu",
              permitido: "todos",
            },
            // {
            //   text: "Registrar producto",
            //   to: "/servicio",
            //   permitido: "todos",
            // },
            {
              text: "Categorías",
              to: "/categorias",
              permitido: "todos",
            },
          ],
        },
        {
          component: "item",
          icon: "receipt_long",
          title: "Ordenes",
          permitido: "todos",
          to: "/ordenes",
        },
        {
          component: "group",
          icon: "insights",
          title: "Reportes",
          permitido: "todos",
          items: [],
        },
        {
          component: "item",
          icon: "place",
          title: "Sucursales",
          permitido: "todos",
          to: "/sucursales",
        },
        //{component:"group",icon:"language",title:"Sitio web",items:[{text:"Publicaciones realizadas",to:"/posts"},{text:"Crear publicación",to:"/post"},],},
        {
          component: "item",
          icon: "account_circle",
          title: "Usuarios",
          to: "/usuarios",
          permitido: "administrativo",
        },
      ],
    };
  },
  methods: {
    _getItems(menu) {},
    _mustShow(permisos = "") {
      // try {
      //   return permisos === "todos"
      //     ? true
      //     : permisos.split(/[,]/).find((e) => e === this.tipo);
      // } catch (err) {
      //   return false;
      // }
      return true;
    },
  },
  created() {
    this.tipo = this.$session.get("tipo");
  },
};
</script>
